import {
  AddressType,
  AddressTypesResponse,
  CatalogFile,
  CitiesResponse,
  City,
  CityResponse,
  Colonia,
  ColoniaResponse,
  FileCatalogResponse,
  PersonType,
  PersonTypesResponse,
  ReasonRejectionApplication,
  ReasonRejectionAppResponse,
  ReasonRejectionDocument,
  ReasonRejectionDocumentResponse,
  ReasonUninterested,
  ReasonUninterestedResponse,
  StagesResponse,
  State,
  StateResponse,
  StatesResponse,
  StatusConfig,
  StatusConfigResponse,
  StatusResponse,
} from "@interfaces/catalogs";
import { baseApi } from "./baseApi";
import { UUID } from "crypto";

const catalogsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStages: build.query<StagesResponse, void>({
      query: () => "catalogos/stage",
    }),
    getStatus: build.query<StatusResponse, void>({
      query: () => ({
        url: "catalogos/status",
        params: {
          size: 100,
        },
      }),
    }),
    getPersonTypes: build.query<Array<PersonType>, void>({
      query: () => ({
        url: "catalogos/person_type",
        params: {
          size: 20,
        },
      }),
      transformResponse: (response: PersonTypesResponse) => {
        return response.data.data;
      },
    }),
    getReasonUninterested: build.query<Array<ReasonUninterested>, void>({
      query: () => ({
        url: "catalogos/razon_uninterested",
      }),
      transformResponse: (response: ReasonUninterestedResponse) => {
        return response.data.data;
      },
    }),
    getReasonRejectionDocs: build.query<Array<ReasonRejectionDocument>, void>({
      query: () => ({
        url: "catalogos/reason_rejection_document",
      }),
      transformResponse: (response: ReasonRejectionDocumentResponse) => {
        return response.data.data;
      },
    }),
    getReasonRejectionApp: build.query<Array<ReasonRejectionApplication>, void>({
      query: () => ({
        url: "catalogos/reason_rejection",
      }),
      transformResponse: (response: ReasonRejectionAppResponse) => {
        return response.data.data;
      },
    }),
    getFileCategories: build.query<Array<CatalogFile>, void>({
      query: () => ({
        url: "catalogos/file_category",
        params: {
          size: 50,
        },
      }),
      transformResponse: (response: FileCatalogResponse) => {
        return response.data.data;
      },
    }),
    getStates: build.query<Array<State>, void>({
      query: () => ({
        url: "catalogos/state",
        params: {
          size: 50,
        },
      }),
      transformResponse: (response: StatesResponse) => {
        return response.data.data;
      },
    }),
    getState: build.query<State, number>({
      query: (stateId) => ({
        url: `catalogos/state/${stateId}`,
      }),
      transformResponse: (response: StateResponse) => {
        return response.data;
      },
    }),
    getCitiesByState: build.query<Array<City>, number>({
      query: (stateId) => ({
        url: `catalogos/state/${stateId}/city`,
        params: {
          size: 1000,
        },
      }),
      transformResponse: (response: CitiesResponse) => {
        return response.data.data;
      },
    }),
    getAddressTypes: build.query<Array<AddressType>, void>({
      query: () => ({
        url: "catalogos/address_type",
        params: {
          size: 20,
        },
      }),
      transformResponse: (response: AddressTypesResponse) => {
        return response.data.data;
      },
    }),
    getCity: build.query<City, number>({
      query: (cityId) => ({
        url: `catalogos/city/${cityId}`,
      }),
      transformResponse: (response: CityResponse) => {
        return response.data;
      },
    }),
    getColonia: build.query<Colonia, number>({
      query: (coloniaId) => ({
        url: `catalogos/colonia/${coloniaId}`,
      }),
      transformResponse: (response: ColoniaResponse) => {
        return response.data;
      },
    }),
    getStatusConfig: build.query<Array<StatusConfig>, { productId: UUID; taxSystemId: UUID }>({
      query: ({ productId, taxSystemId }) => ({
        url: "catalogos/status_config",
        params: {
          product_id: productId,
          tax_system_id: taxSystemId,
        },
      }),
      transformResponse: (response: StatusConfigResponse) => {
        return response.data.data;
      },
    }),
  }),
});

export const {
  useGetStagesQuery,
  useGetStatusQuery,
  useGetStatesQuery,
  useGetStateQuery,
  useGetCityQuery,
  useGetColoniaQuery,
  useLazyGetCitiesByStateQuery,
  useLazyGetStatusQuery,
  useGetPersonTypesQuery,
  useLazyGetPersonTypesQuery,
  useGetReasonUninterestedQuery,
  useGetReasonRejectionDocsQuery,
  useGetReasonRejectionAppQuery,
  useLazyGetReasonRejectionAppQuery,
  useGetFileCategoriesQuery,
  useLazyGetFileCategoriesQuery,
  useGetAddressTypesQuery,
  useGetStatusConfigQuery,
} = catalogsApi;
