import { MigrationManifest, PersistedState } from "redux-persist";

import { DialogType, OptionApplicationMenu, DocumentAction } from "@interfaces/slices";

export const migrations: MigrationManifest = {
  0: (state: PersistedState) => {
    return {
      ...state,
      app: {
        ...state.app,
        components: {
          ...state.app.components,
          printingProfileReport: {
            printingMode: false,
            module: {
              generalReady: false,
              downloadSatReady: false,
              profileReady: false,
              dashboardReady: false,
              financialReady: false,
            },
          },
        },
      },
    };
  },
  1: (state: PersistedState) => {
    delete state.app["components"];
    return {
      ...state,
      app: {
        ...state.app,
      },
      components: {
        ...state.components,
        dialog: {
          shown: false,
          type: DialogType.NONE,
          props: undefined,
          onRejectClick: () => {},
          onAcceptClick: () => {},
          onCloseClick: () => {},
        },
        buttonPanel: {
          buttons: [],
          buttonClicked: {
            clicked: false,
            btn: undefined,
          },
        },
        menuApplication: {
          optionSelected: OptionApplicationMenu.INFO_GRAL,
        },
        documentAction: {
          action: DocumentAction.SEE,
          doc: undefined,
        },
        printingProfileReport: {
          printingMode: false,
          module: {
            generalReady: false,
            downloadSatReady: false,
            profileReady: false,
            dashboardReady: false,
            financialReady: false,
          },
        },
        shareholdersStructure: {
          shareholders: [],
        },
      },
    };
  },
  2: (state: PersistedState) => {
    delete state.components["toAdd"];
    delete state.components["toDelete"];
    delete state.components["toUpdate"];
    return {
      ...state,
      app: {
        ...state.app,
      },
      components: {
        ...state.components,
        shareholdersStructure: {
          ...state.components.shareholdersStructure,
          editingMode: false,
        },
      },
    };
  },
  3: (state: PersistedState) => {
    return {
      ...state,
      app: {
        ...state.app,
      },
      components: {
        ...state.components,
        menuApplication: {
          optionSelected: OptionApplicationMenu.INFO_GRAL,
        },
      },
    };
  },
  4: (state: PersistedState) => {
    return {
      ...state,
      app: {
        ...state.app,
        applicationSelected: {
          ...state.app.applicationSelected,
          detalleCanal: "",
        },
      },
    };
  },
  5: (state: PersistedState) => {
    return {
      ...state,
      components: {
        ...state.components,
        popup: {
          type: PopupType.NONE,
          props: undefined,
          anchorEl: undefined,
        },
      },
    };
  },
  6: (state: PersistedState) => {
    return {
      ...state,
      app: {
        ...state.app,
        session: {
          ...state.app.session,
          companies: [],
        },
      },
    };
  },
};
