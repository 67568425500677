import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid2 as Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import { DialogType } from "@interfaces/slices";
import { setDialogConfig } from "@store/slices/componentsSlice";
import { useGetReasonRejectionAppQuery } from "@services/api/catalogs";
import { usePostReasonRejectAppMutation, usePostStatusApplicationMutation } from "@services/api/applications";

export interface RechazarSolicitudProps {
  type?: "Reject" | "Block";
  onBackClick: () => void;
  onConfirmClick?: () => void;
}

export const RechazarSolicitud = (props: RechazarSolicitudProps) => {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const userSession = useSelector((state: RootState) => state.app.session.user);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const reasonsRejection = useGetReasonRejectionAppQuery();

  const [triggerReasonReject] = usePostReasonRejectAppMutation();
  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const formik = useFormik({
    initialValues: {
      option: "",
      other: "",
    },
    validationSchema: yup.object().shape({
      option: yup.string().required(),
      other: yup.string().when("option", {
        is: "CRROT",
        then: (schema) => schema.required("Este campo es requerido para la opción 'Otro'."),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit: async (values) => {
      try {
        // console.log([
        //   {
        //     reason_rejection_id:
        //       reasonsRejection.currentData?.find((item) => item.code == values.option)?.id ?? "0-0-0-0-0",
        //     other_reason: values.other,
        //   },
        // ]);
        setIsSaving(true);
        await triggerReasonReject({
          applicationId: applicationSelected.id,
          payload: [
            {
              reason_rejection_id:
                reasonsRejection.currentData?.find((item) => item.code == values.option)?.id ?? "0-0-0-0-0",
              other_reason: values.other,
            },
          ],
        }).unwrap();

        await triggerPostStatusApplication({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          userName: userSession.name.concat(" ", userSession.last_name, " ", userSession.last_name_2).trim(),
          statusCode: props.type == "Reject" ? "CSRDO" : "CSREC",
          disableSequenceCheck: true,
        }).unwrap();

        dispatch(
          setDialogConfig({
            shown: false,
            type: DialogType.NONE,
            props: undefined,
          }),
        );

        props.onConfirmClick?.();
      } catch (error) {
        console.log("Error: %s", error);
      } finally {
        setIsSaving(false);
      }
    },
  });

  const handleOnChangeOption = (event: React.ChangeEvent<HTMLInputElement>, _: boolean) => {
    formik.setFieldValue("option", event.currentTarget.value);
  };

  const handleOnBackClicked = () => {
    dispatch(
      setDialogConfig({
        shown: false,
        type: DialogType.NONE,
        props: undefined,
      }),
    );
    props.onBackClick();
  };

  return (
    <Box margin={4}>
      <Stack textAlign={"center"} spacing={2}>
        <Typography variant="body1" fontWeight={600} color={"#002652"}>
          Selecciona el motivo para{" "}
          <Typography component={"span"} variant="body1" fontWeight={600} color={"#F2704F"}>
            {props.type === "Reject" && "rechazar "}
            {props.type === "Block" && "bloquear "}
          </Typography>
          a:
        </Typography>
        <Typography variant="h4" fontWeight={600} color={"#528CD6"}>
          {applicationSelected.razonSocial}
        </Typography>
        <Typography variant="h4" color={"#528CD6"}>
          ID SOLICITUD:{" "}
          <Typography component={"span"} variant="h4" fontWeight={600}>
            {applicationSelected.solicitudId}
          </Typography>{" "}
        </Typography>
        <form onSubmit={formik.handleSubmit} noValidate>
          <FormGroup>
            {reasonsRejection.isSuccess ? (
              reasonsRejection.data.map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      name={"option"}
                      checked={formik.values.option === item.code}
                      onChange={handleOnChangeOption}
                      value={item.code}
                    />
                  }
                  label={item.name}
                />
              ))
            ) : (
              <>
                <Skeleton variant="text" width={"100%"} />
                <Skeleton variant="text" width={"100%"} />
                <Skeleton variant="text" width={"100%"} />
                <Skeleton variant="text" width={"100%"} />
              </>
            )}
          </FormGroup>
          {formik.values.option === "CRROT" && (
            <TextField
              required
              fullWidth
              name="other"
              label={"Escribe el motivo"}
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.other}
              error={formik.touched.other && Boolean(formik.errors.other)}
              helperText={formik.touched.other && formik.errors.other}
            />
          )}

          <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-around"}
            alignItems={"center"}
            marginTop={10}
            columnSpacing={2}
          >
            <Grid>
              <Button variant={"blue_outlined"} onClick={handleOnBackClicked}>
                Regresar
              </Button>
            </Grid>
            <Grid>
              <Button
                variant={"contained"}
                type="submit"
                loading={isSaving}
                disabled={
                  reasonsRejection.isLoading ||
                  !formik.dirty ||
                  !formik.isValid ||
                  !(Object.keys(formik.errors).length === 0)
                }
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </Box>
  );
};
