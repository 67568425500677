import { ClickAwayListener, Container, Grid2 as Grid, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { useDispatch } from "react-redux";

import { PopupType } from "@interfaces/slices";
import { AbortiveStatus } from "@interfaces/application";
import { setPopupConfig } from "@store/slices/componentsSlice";

import DownloadWaitIcon from "@assets/download_wait_icon.svg";
import DownloadWarningIcon from "@assets/download_warning_icon.svg";

interface EstatusAbortivosProps {
  status: AbortiveStatus | undefined;
}

export const EstatusAbortivos = (props: EstatusAbortivosProps) => {
  const dispatch = useDispatch();

  const handleOnAbortiveDetailClicked = (e: any, details: any) => {
    const abortiveType = e.target.dataset.abortive;
    dispatch(
      setPopupConfig({
        type: PopupType.DETALLE_ABORTIVO,
        anchorEl: abortiveType,
        props: {
          details: details,
        },
      }),
    );
  };

  const handleOnClose = () => {
    dispatch(
      setPopupConfig({
        type: PopupType.NONE,
        props: undefined,
        anchorEl: undefined,
      }),
    );
  };

  const createSectionStatus = (title: string, status: boolean | null, details: any, addMenu: boolean = false) => {
    const tmp = title.replace(" ", "_").replace(".", "").toLowerCase();
    return (
      <Grid>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: addMenu ? "pointer" : "default",
          }}
          onClick={
            addMenu
              ? (e) => {
                  handleOnAbortiveDetailClicked(e, details);
                }
              : handleOnClose
          }
          data-abortive={tmp}
        >
          {status === false && <CheckIcon sx={{ color: "#528CD6", width: 13, height: 11 }} />}
          {status === true && <img src={DownloadWarningIcon} />}
          {status === null && <img src={DownloadWaitIcon} />}
          <Typography variant="body3" fontWeight={500} color="#002652" mt={0.2} data-abortive={tmp}>
            {title}
          </Typography>
          <Typography
            variant="body3"
            fontWeight={500}
            color={status === true ? "main4" : status === null ? "secondary4" : "main2"}
            data-abortive={tmp}
            id={`${tmp}_status`}
          >
            {status === false ? "Cumple" : status === null ? "Procesando" : "No Cumple"}
          </Typography>
        </Container>
      </Grid>
    );
  };

  const validateState = (section: any): boolean | null => {
    if (section === undefined || section === null) return null;

    const keys = Object.keys(section);
    const withoutAllAbortives = keys.filter((key) => key !== "show_menu").every((t) => section[t] === false);
    const withAllAbortives = keys.filter((key) => key !== "show_menu").every((t) => section[t] === true);
    const withAllProcessing = keys.filter((key) => key !== "show_menu").every((t) => section[t] === null);

    if (withoutAllAbortives) return false;
    if (withAllAbortives) return true;
    if (withAllProcessing) return null;

    const someProcessing = keys.filter((key) => key !== "show_menu").some((t) => section[t] === null);
    if (someProcessing) return null;

    const someAbortive = keys.filter((key) => key !== "show_menu").some((t) => section[t] === true);
    if (someAbortive) return true;

    return false;
  };

  return (
    <ClickAwayListener onClickAway={handleOnClose}>
      <Grid
        py={2}
        rowSpacing={2}
        container
        sx={{ backgroundColor: "#E3F2F8", borderRadius: "8px" }}
        justifyContent={"space-between"}
      >
        {createSectionStatus(
          "Ventas",
          validateState(props.status?.sales),
          props.status?.sales,
          props.status?.sales?.show_menu ?? false,
        )}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus(
          "Cap. Pago",
          validateState(props.status?.payment_capacity),
          props.status?.payment_capacity,
          props.status?.payment_capacity?.show_menu ?? false,
        )}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus(
          "Antigüedad",
          validateState(props.status?.seniority),
          props.status?.seniority,
          props.status?.seniority?.show_menu ?? false,
        )}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus(
          "Declaración",
          validateState(props.status?.declaration),
          props.status?.declaration,
          props.status?.declaration?.show_menu ?? false,
        )}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus(
          "Actividad",
          validateState(props.status?.activity),
          props.status?.activity,
          props.status?.activity?.show_menu ?? false,
        )}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus(
          "Zona",
          validateState(props.status?.zone),
          props.status?.zone,
          props.status?.zone?.show_menu ?? false,
        )}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus(
          "Edad Acc.",
          validateState(props.status?.shareholder_or_pfae_age),
          props.status?.shareholder_or_pfae_age,
          props.status?.shareholder_or_pfae_age?.show_menu ?? false,
        )}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus(
          "Buró Acred",
          validateState(props.status?.credit_bureau_accredited),
          props.status?.credit_bureau_accredited,
          props.status?.credit_bureau_accredited?.show_menu ?? false,
        )}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus(
          "Buró Persona",
          validateState(props.status?.credit_bureau_shareholder_or_pf),
          props.status?.credit_bureau_shareholder_or_pf,
          props.status?.credit_bureau_shareholder_or_pf?.show_menu ?? false,
        )}
      </Grid>
    </ClickAwayListener>
  );
};
