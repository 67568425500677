import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid2 as Grid, Typography } from "@mui/material";

import { RootState } from "@store/store";
import { ButtonType, OptionApplicationMenu, PopupType } from "@interfaces/slices";
import { setOptionAppSelected, setPopupConfig, setVisibleButtons } from "@store/slices/componentsSlice";

export const Options = () => {
  const dispatch = useDispatch();

  const optionPrevSelected = useSelector((state: RootState) => state.components.menuApplication.optionSelected);

  const [optionSelected, setOptionSelected] = useState<OptionApplicationMenu>(optionPrevSelected);

  const handleOnChangeOption = (option: OptionApplicationMenu) => {
    dispatch(
      setPopupConfig({
        type: PopupType.NONE,
        props: undefined,
        anchorEl: undefined,
      }),
    );

    setOptionSelected(option);

    let btns = Array<{ btn: ButtonType; disabled: boolean }>();
    switch (option) {
      case OptionApplicationMenu.PERFIL_CREDITICIO:
      case OptionApplicationMenu.INFO_GRAL:
        btns.push(
          // { btn: ButtonType.VALIDAR_AUTORIZACION, disabled: false },
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        break;
      // case OptionApplicationMenu.PERFIL:
      //   btns.push(
      //     { btn: ButtonType.VALIDAR_AUTORIZACION, disabled: false },
      //     { btn: ButtonType.RECHAZAR, disabled: false },
      //     { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
      //   );
      //   break;
      case OptionApplicationMenu.VISITA_OCULAR:
        btns.push(
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        break;
      // case OptionApplicationMenu.DICTAMEN:
      case OptionApplicationMenu.DICTAMEN_LEGAL:
      case OptionApplicationMenu.CONTRACTUALES:
        btns.push(
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        break;
      case OptionApplicationMenu.EXPEDIENTE:
        btns.push(
          { btn: ButtonType.VALIDAR_EXPEDIENTE, disabled: false },
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        break;
      case OptionApplicationMenu.RESUMEN_CLIENTE:
        btns.push(
          { btn: ButtonType.LIBERAR_DISPERSION, disabled: false },
          { btn: ButtonType.CONVERTIR_CLIENTE, disabled: false },
        );
        break;
    }

    dispatch(setVisibleButtons(btns));
    dispatch(setOptionAppSelected(option));
  };

  useEffect(() => {
    handleOnChangeOption(optionPrevSelected);
  }, [optionPrevSelected]);

  return (
    <Grid container>
      <Grid size={10}>
        <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.INFO_GRAL ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.INFO_GRAL)}
          >
            Info. General
          </Typography>

          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.PERFIL_CREDITICIO ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.PERFIL_CREDITICIO)}
          >
            Perfil Crediticio
          </Typography>
        </Grid>
        <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.DICTAMEN_LEGAL ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.DICTAMEN_LEGAL)}
          >
            Dictamen Legal
          </Typography>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.EXPEDIENTE ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.EXPEDIENTE)}
          >
            Expediente
          </Typography>
        </Grid>
        <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.VALIDACION_CREDITICA ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.VALIDACION_CREDITICA)}
          >
            Validación Crediticia
          </Typography>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.LEGAL_PLD ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.LEGAL_PLD)}
          >
            Legal y PLD
          </Typography>
        </Grid>
        <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.VISITA_OCULAR ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.VISITA_OCULAR)}
          >
            Visita Ocular
          </Typography>
        </Grid>
        <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.REVISION_CONTROL ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.REVISION_CONTROL)}
          >
            Revisión Control
          </Typography>
        </Grid>
        <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.CONTRACTUALES ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.CONTRACTUALES)}
          >
            Contractuales
          </Typography>
        </Grid>
        <Grid borderRight={"1px solid #A3D4E8"} p={1}>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.RESUMEN_CLIENTE ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.RESUMEN_CLIENTE)}
          >
            Resumen Cliente
          </Typography>
        </Grid>
        <Grid borderRight={"1px solid #A3D4E8"} p={1} minHeight={"50px"}></Grid>
      </Grid>
      <Grid size={"auto"}></Grid>
    </Grid>
  );
};
