import { combineReducers, configureStore, Dispatch, UnknownAction } from "@reduxjs/toolkit";

import { useDispatch } from "react-redux";
import storage from "redux-persist/lib/storage";

import { thunk, ThunkDispatch } from "redux-thunk";
import { setupListeners } from "@reduxjs/toolkit/query";

import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, createMigrate } from "redux-persist";

import appReducer from "./slices/appSlice";
import componentsReducer from "./slices/componentsSlice";

import { baseApi } from "@services/api/baseApi";
import { migrations } from "./migrations/migrations";

const appsReducer = combineReducers({
  app: appReducer,
  components: componentsReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const rootReducer = (state: any, action: UnknownAction) => {
  if (action.type === "USER_LOGOUT" || action.type === "CLEAN_STORE") {
    storage.removeItem("persist:root");
    return appsReducer(undefined, action);
  }

  return appsReducer(state, action);
};

const persistedReducer = persistReducer(
  {
    key: "lenditgroup",
    storage: storage,
    whitelist: ["app", "components"],
    blacklist: [baseApi.reducerPath],
    version: 6,
    migrate: createMigrate(migrations, { debug: true }),
  },
  rootReducer,
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "components",
          "components/setDialogConfig",
          "components/setPopupConfig",
        ],
        ignoredActionPaths: ["components", "meta.arg", "meta.baseQueryMeta"],
        ignoredPaths: ["components"],
      },
    })
      .concat(thunk)
      .concat(baseApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

type AppDispatch = ThunkDispatch<any, undefined, UnknownAction> & Dispatch<any>;
export const useAppDispatch: () => AppDispatch = useDispatch;
