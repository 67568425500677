import { useEffect, useState } from "react";
import { Alert, Box, LinearProgress } from "@mui/material";

import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import { DialogType } from "@interfaces/slices";
import { setDialogConfig, setPrintingReset } from "@store/slices/componentsSlice";

import { Perfil } from "@components/SolicitudSegmentos/PerfilCrediticio/Perfil";
import { General } from "@components/SolicitudSegmentos/InfoGeneral/General";
import { Dashboard } from "@components/SolicitudSegmentos/PerfilCrediticio/Dashboard";
import { Financieros } from "@components/SolicitudSegmentos/PerfilCrediticio/Financieros";

export const DescargarReporte = () => {
  const dispatch = useDispatch();

  const [progress, setProgress] = useState<number>(0);

  const printingProfileReport = useSelector((state: RootState) => state.components.printingProfileReport);

  const handleOnClick = async () => {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "A4",
      //   compress: true
    });

    const elements = document.querySelectorAll(".seccion_print");

    for (const [index, item] of elements.entries()) {
      const element = document.getElementById(item.getAttribute("id") ?? "");
      if (!element) continue;

      setProgress(Math.round(((index + 1) / elements.length) * 80) + 20);

      const canvas = await html2canvas(element, { scale: 2 });
      const data = canvas.toDataURL("image/jpeg");
      //   const data = canvas.toDataURL("image/webp", 0.2);
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(data, "jpeg", 0, 10, pdfWidth, pdfHeight);
      //   pdf.addImage(data, "webp", 0, 10, pdfWidth, pdfHeight, undefined, "MEDIUM");
      pdf.addPage();
    }

    pdf.deletePage(elements.length + 1);

    await pdf.save(`ReporteSolicitud_${dayjs().format("YYYYMMDD_HHmmss")}.pdf`, { returnPromise: true });

    dispatch(setPrintingReset());
    dispatch(
      setDialogConfig({
        shown: false,
        fullscreen: false,
        type: DialogType.NONE,
        props: undefined,
      }),
    );
  };

  useEffect(() => {
    if (
      printingProfileReport.module.generalReady &&
      printingProfileReport.module.downloadSatReady &&
      printingProfileReport.module.profileReady &&
      printingProfileReport.module.dashboardReady &&
      printingProfileReport.module.financialReady
    ) {
      setProgress(20);
      setTimeout(() => {
        handleOnClick();
      }, 3000);
    }
  }, [printingProfileReport]);

  return (
    <>
      <Box minWidth={100}>
        <Alert severity="info">Generando reporte... {progress}%</Alert>
      </Box>
      <Box>
        <LinearProgress variant="determinate" value={progress} sx={{ height: 10 }} />
      </Box>
      <Box overflow={"scroll"}>
        <Box id="seccion1" className="seccion_print" minWidth={1920}>
          <Box mx={15}>
            <General />
            <Perfil />
          </Box>
        </Box>
        <Box id="seccion2" className="seccion_print" minWidth={1920}>
          <Box mx={15}>
            <Dashboard />
          </Box>
        </Box>
        <Box id="seccion3" className="seccion_print" minWidth={1920}>
          <Box mx={15}>
            <Financieros />
          </Box>
        </Box>
      </Box>
    </>
  );
};
